import { Avatar, Button, HStack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { AlertIcon, ArrowCounterClockwiseIcon, Callout } from "@pomebile/design-system"
import { StickyBottom } from "../components/StickyBottom"
import { useRecipientVerificationVeriff } from "../hooks/useRecipientVerificationVeriff"
import { RecipientVerificationVeriffSdkResponse } from "../api/webRoutes"
import { segmentAnalyticsLogger } from "../utils/segment"

interface RecipientVerificationRetryIdentityVerificationProps {
  veriffUrl: string

  api: {
    startVeriffSdk: (url: string) => Promise<RecipientVerificationVeriffSdkResponse>
  }

  onDone: () => void
}

export function RecipientVerificationRetryIdentityVerification({
  veriffUrl,
  api,
  onDone,
}: RecipientVerificationRetryIdentityVerificationProps) {
  const { buttonState, buttonText, sdkError, startSdk } = useRecipientVerificationVeriff({
    veriffUrl,
    onDone,
    api,
  })

  const handleClick = () => {
    segmentAnalyticsLogger.logEvent("Veriff retry continue clicked")
    startSdk()
  }

  return (
    <ScreenFrame>
      <VStack height="full">
        <VStack height="full" justifyContent="center" alignItems="center">
          <Avatar size="lg" svg={<ArrowCounterClockwiseIcon fill="icon-emphasis" />} />
          <Txt variant="headline2" textAlign="center">
            Retry identity verification
          </Txt>
          <Txt textAlign="center">
            We were unable to successfully verify your identity. Please try again to continue.
          </Txt>
        </VStack>

        <StickyBottom>
          <VStack gap="md">
            {sdkError && (
              <VStack gap="md">
                <Callout variant="error">
                  <HStack alignItems="center" gap="sm">
                    <AlertIcon fill="icon-error" />
                    <Txt variant="body2" color="text-error">
                      {sdkError}
                    </Txt>
                  </HStack>
                </Callout>
              </VStack>
            )}

            <VStack gap="sm" alignItems="center">
              <Button state={buttonState} onClick={handleClick}>
                {buttonText}
              </Button>
            </VStack>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
