import { VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { useEffect } from "react"
import { useLogging } from "../utils/logging"

type RecipientVerificationOutcome =
  | {
      tag: "success"
    }
  | {
      tag: "resubmissionRequired"
      url: string
    }
  | {
      tag: "unexpectedError"
    }
  | {
      tag: "timeoutError"
    }
  | {
      tag: "failed"
    }

export type RecipientVerificationVeriffDecision =
  | {
      tag: "declined"
    }
  | {
      tag: "approved"
    }
  | {
      tag: "resubmit"
      url: string
    }
  | {
      tag: "timeoutError"
    }
  | {
      tag: "unexpectedError"
    }

interface RecipientVerificationFetchingVeriffDecisionProps {
  api: {
    queryVeriffDecision: () => Promise<RecipientVerificationVeriffDecision>
  }

  onDone: (outcome: RecipientVerificationOutcome) => void
}

export function RecipientVerificationFetchingVeriffDecision({
  api: { queryVeriffDecision },
  onDone,
}: RecipientVerificationFetchingVeriffDecisionProps) {
  const { logError } = useLogging()

  useEffect(() => {
    queryVeriffDecision()
      .then((decision) => {
        switch (decision.tag) {
          case "resubmit":
            onDone({ tag: "resubmissionRequired", url: decision.url })
            break

          case "approved":
            onDone({ tag: "success" })
            break

          case "declined":
            onDone({ tag: "failed" })
            break

          case "timeoutError":
            onDone({ tag: "timeoutError" })
            break

          default:
            onDone({ tag: "unexpectedError" })
            break
        }
      })
      .catch((err) => {
        logError(err)
        onDone({ tag: "unexpectedError" })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScreenFrame>
      <VStack alignItems="center" justifyContent="center" height="full">
        <img src="images/pomelo_spinner_gray.gif" alt="Loading Spinner" width={60} height={60} />
      </VStack>
    </ScreenFrame>
  )
}
