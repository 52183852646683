import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { AlertIcon } from "@pomebile/design-system"
import { ScreenFrame } from "../components/ScreenFrame"

export function RecipientVerificationRejectedScreen() {
  return (
    <ScreenFrame>
      <VStack justifyContent="center" alignItems="center" height="full" gap="xs" padding="xl2">
        <VStack justifyContent="center" alignItems="center" width="unset">
          <Avatar
            size="lg"
            variant="error"
            svg={<AlertIcon fill="icon-error" width={34} height={34} />}
          />
          <Txt variant="headline2" as="h1" textAlign="center">
            Unable to verify your identity at this time
          </Txt>
        </VStack>
        <Txt textAlign="center">
          The transfer has been cancelled. Contact your loved one to send you a new transfer.
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
