/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  CancelDisbursementResponse,
  ConfirmMdv200Response,
  ConfirmMdvRequest,
  Get200Response,
} from "../models"

export const cancelDisbursement = {
  url: "/v1/phBankVerification/cancelDisbursement/{paymentTransactionId}",
  method: "POST",
  meta: {} as {
    pathParams: {
      paymentTransactionId: /* IS NOT ENUM */ string
    }
    res: CancelDisbursementResponse
  },
} as const

export const confirmMdv = {
  url: "/v1/phBankVerification/confirmMdv",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: ConfirmMdvRequest
    res: ConfirmMdv200Response
  },
} as const

export const get = {
  url: "/v1/phBankVerification/{id}",
  method: "GET",
  meta: {} as {
    pathParams: {
      id: /* IS NOT ENUM */ string
    }
    res: Get200Response
  },
} as const
