import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { AlertIcon } from "@pomebile/design-system"

export function RecipientVerificationTooManyAttempts() {
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="center" alignItems="center" gap="xs2">
        <Avatar
          size="lg"
          variant="action"
          svg={<AlertIcon width={35} height={35} fill="icon-brand" />}
        />
        <Txt variant="headline2" textAlign="center">
          Unable to confirm bank account
        </Txt>
        <Txt textAlign="center">
          The transfer has been cancelled. Contact your loved one to send you a new transfer.
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
