import { useState } from "react"
import { RecipientVerificationVeriffSdkResponse, VeriffSdkError } from "../api/webRoutes"
import { useLogging } from "../utils/logging"
import { NetworkError } from "../api/issueRequest"
import { ButtonState } from "@pomebile/primitives"

interface UseRecipientVerificationVeriffProps {
  veriffUrl: string | undefined

  api: {
    startVeriffSdk: (url: string) => Promise<RecipientVerificationVeriffSdkResponse>
  }

  onDone: () => void
}

type VeriffSdkOutcome =
  | { tag: "idle" }
  | { tag: "pending" }
  | { tag: "done" }
  | { tag: "error"; reason: VeriffSdkError }

export const useRecipientVerificationVeriff = ({
  veriffUrl,
  api,
  onDone,
}: UseRecipientVerificationVeriffProps) => {
  const { logError } = useLogging()

  const [veriffSdkStatus, setStatus] = useState<VeriffSdkOutcome>({ tag: "idle" })

  const startSdk = async () => {
    if (!veriffUrl) {
      onDone()
      return
    }

    if (veriffSdkStatus.tag === "error" && veriffSdkStatus.reason === "sdk_needs_reload") {
      window.location.reload()
      return
    }

    setStatus({ tag: "pending" })

    try {
      const result = await api.startVeriffSdk(veriffUrl)

      switch (result.type) {
        case "reload_required":
          setStatus({ tag: "error", reason: "sdk_needs_reload" })
          break

        case "user_cancelled":
          setStatus({ tag: "error", reason: "user_cancelled" })
          break

        case "finished":
          setStatus({ tag: "done" })
          onDone()
          break
      }
    } catch (error) {
      logError(error)

      if (!(error instanceof NetworkError)) {
        setStatus({ tag: "error", reason: "network_error" })
      } else {
        setStatus({ tag: "error", reason: "cannot_open" })
      }
    }
  }

  let sdkError: string | undefined
  let buttonText = "Continue"
  let buttonState: ButtonState = "active"

  switch (veriffSdkStatus.tag) {
    case "idle":
      buttonText = "Continue"
      buttonState = "active"
      break

    case "pending":
      buttonState = { loadingText: "Starting Identity Verification..." }
      break

    case "error":
      switch (veriffSdkStatus.reason) {
        case "user_cancelled":
          sdkError = "Identity verification is required to continue, please resume verification."
          buttonText = "Resume Verification"
          break

        case "sdk_needs_reload":
          sdkError =
            "An error occurred during verification. Please ensure you allow camera permissions when prompted. You must reload the page to try again."
          buttonText = "Reload"
          break

        case "cannot_open":
          sdkError = "Something went wrong. Please try again."
          buttonText = "Continue"
          break

        case "network_error":
          sdkError = "Unable to connect to internet. Please reconnect to try again."
          buttonText = "Continue"
          break
      }
      break

    default:
      sdkError = undefined
      buttonText = "Continue"
      buttonState = "active"
      break
  }

  return { startSdk, status: veriffSdkStatus, sdkError, buttonText, buttonState }
}
