import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { AlertIcon } from "@pomebile/design-system"

export function RecipientVerificationInvalidLink() {
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="center" alignItems="center">
        <Avatar
          size="lg"
          variant="action"
          svg={<AlertIcon width={35} height={35} fill="icon-brand" />}
        />
        <Txt variant="headline2" textAlign="center">
          This link is no longer valid
        </Txt>
        <Txt textAlign="center">
          The link you’ve used has expired or is invalid. Contact your loved one to send you a new,
          valid invite link to receive the transfer.
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
